import React from 'react';

function FileUpload(props) {
  const {field, form, name, id, setFile} = props

  const handleChange = (e) => {
    if (e.currentTarget.files.length) {
      const file = e.currentTarget.files[0]
      const reader = new FileReader()
      reader.onload = function(event) {
        console.log(event);
      }
      
      reader.readAsDataURL(file)
      setFile(file.name)
      form.setFieldValue(field.name, file)
    }
  };

  return (
    <input className="file-input" type={'file'} id={id} name={name} onChange={(o) => handleChange(o)} />
  );
}

export default FileUpload;