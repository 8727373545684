import React, { useState } from "react"
import { graphql } from "gatsby"
import Layout from "./layout"
import { Formik, Field, ErrorMessage, Form } from "formik"
import { string, object } from "yup"
import axios from "axios"
import FileUpload from "./file-upload"

export default function Template({
  data, // this prop will be injected by the GraphQL query below.
}) {
  const { markdownRemark } = data // data.markdownRemark holds your post data
  const { frontmatter, html } = markdownRemark
  const [errorState, setErrorState] = useState(false)
  const [formState, setFormState] = useState(true)
  const [file, setFile] = useState(undefined)

  function formReady() {
    return (
      <Formik
        initialValues={{
          applyName: "",
          applyMobileNo: "",
          applyEmail: "",
          applyResume: "",
          applyMessage: "",
        }}
        validationSchema={object().shape({
          applyName: string().required("Please enter your full name"),
          applyEmail: string()
            .required("This field is mandatory")
            .email("Must be a valid email address"),
        })}
        onSubmit={(values, { setSubmitting }) => {
          setSubmitting(false)
          setFormState(false)

          let payload = new FormData()
          payload.set("apiType", "apply-job")
          payload.set("applyName", values.applyName)
          payload.set("applyMobileNo", values.applyMobileNo)
          payload.set("applyEmail", values.applyEmail)
          payload.append("applyResume", values.applyResume)
          payload.set("applyMessage", values.applyMessage)
          payload.set("jobName", "TBD")

          axios({
            method: "post",
            url: "https://www.prasthana.com/api/",
            data: payload,
            headers: { "Content-Type": "multipart/form-data" },
          }).then(function (response) {
            //handle success
            setSubmitting(false);
          }).catch(function (e) {
            //handle error
            setErrorState(e.message);
          });
        }}
      >
        {({ isSubmitting, values, errors, touched }) => (
          <Form>
            <div className="control-group">
              <div>
                <div
                  className={`form-control${
                    values.applyName !== "" ? " is-dirty" : ""
                  }${
                    errors.applyName && touched.applyName ? " has-errors" : ""
                  }`}
                >
                  <Field id="applyName" type="text" name="applyName" />
                  <label htmlFor="applyName">Your name</label>
                </div>
                <ErrorMessage
                  className="form-error"
                  name="applyName"
                  component="div"
                />
              </div>
              <div>
                <div
                  className={`form-control${
                    values.applyMobileNo !== "" ? " is-dirty" : ""
                  }${
                    errors.applyMobileNo && touched.applyMobileNo
                      ? " has-errors"
                      : ""
                  }`}
                >
                  <Field id="applyMobileNo" type="tel" name="applyMobileNo" />
                  <label htmlFor="applyMobileNo">Mobile number</label>
                </div>
                <ErrorMessage
                  className="form-error"
                  name="applyMobileNo"
                  component="div"
                />
              </div>
            </div>
            <div className="control-group">
              <div>
                <div
                  className={`form-control${
                    values.applyEmail !== "" ? " is-dirty" : ""
                  }${
                    errors.applyEmail && touched.applyEmail ? " has-errors" : ""
                  }`}
                >
                  <Field id="applyEmail" type="email" name="applyEmail" />
                  <label htmlFor="applyEmail">Your email address</label>
                </div>
                <ErrorMessage
                  className="form-error"
                  name="applyEmail"
                  component="div"
                />
              </div>
              <div>
                <div
                  className={`form-control file-form${
                    values.applyResume !== "" ? " is-dirty" : ""
                  }${errors.applyResume ? " has-errors" : ""}`}
                >
                  <div>Resume: </div>
                  <Field
                    id="applyResume"
                    component={FileUpload}
                    name="applyResume"
                    setFile={setFile}
                  />
                  {file ? (
                    <>
                      <label htmlFor="applyResume">{file}</label>
                      <button
                        onClick={() => setFile(undefined)}
                        className="remove-file"
                      >
                        x
                      </button>
                    </>
                  ) : (
                    <label className="btn-label" htmlFor="applyResume">
                      Choose File
                    </label>
                  )}
                </div>
                <ErrorMessage
                  className="form-error"
                  name="applyResume"
                  component="div"
                />
              </div>
            </div>
            <div
              className={`form-control textarea${
                values.applyMessage !== "" ? " is-dirty" : ""
              }`}
            >
              <Field id="applyMessage" as="textarea" name="applyMessage" placeholder="Type message here" />
            </div>
            {errorState && (
              <div className="error-block">
                {errorState}: Please try again later
              </div>
            )}
            <button
              className="button secondary is-large"
              type="submit"
              disabled={isSubmitting}
            >
              Submit
            </button>
          </Form>
        )}
      </Formik>
    )
  }
  function formSent() {
    return (
      <div>
        <p>Thank you!</p>
        <p>
          We got your application. We’ll get in contact with you as soon as
          possible from our email{" "}
          <a href="mailto:info@prasthana.com">info@prasthana.com</a>
        </p>
      </div>
    )
  }
  return (
    <Layout>
      <header className="page-header">
        <h1>Careers</h1>
      </header>
      <section className="highlighted career-page">
        <div className="career-card">
          <h2>{frontmatter.title}</h2>
          <div
            className="blog-post-content"
            dangerouslySetInnerHTML={{ __html: html }}
          />
          <h2 id="apply">Apply</h2>
          {formState ? formReady() : formSent()}
        </div>
      </section>
    </Layout>
  )
}
export const pageQuery = graphql`
  query($slug: String!) {
    markdownRemark(frontmatter: { slug: { eq: $slug } }) {
      html
      frontmatter {
        slug
        title
      }
    }
  }
`
